.breadcrumb {
    background: transparent !important;
}
.details-td {
    padding: 0 !important;
}

.head-td {
    font-weight: 600;
    width: 120px;
}
