/* ********************************************************************************************
    Reset
*********************************************************************************************** */
body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  background-color: #f6f8fa;
}

.actionIcons {
  display: block !important;
}

.sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu,
.site-logo li .brand-text,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.nav.metismenu,
body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", sans-serif;
}

.text-default {
  color: #617182 !important;
}

.text-accent-custom {
  color: #68439a !important;
}

a.default-color:active,
a.default-color:hover,
a:active,
a:hover {
  color: #68439a;
}

.o-contain {
  object-fit: contain;
}

.o-cover {
  object-fit: cover;
}

.w-100p,
.w-100p ~ .select2-container {
  width: 100% !important;
}

a,
.pointer {
  cursor: pointer !important;
}

.actions > li {
  vertical-align: top;
}

.tab-pane .actions.top-right {
  top: 10px;
}

strong {
  font-weight: 600;
}

.font-weight400 {
  font-weight: 400;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

/* ********************************************************************************************
    Page Header & Page Content
*********************************************************************************************** */

.page-header h1 {
  font-weight: 600;
}

.page-content {
  padding-top: 20px;
}

.page-sub-header h1 {
  font-weight: 500;
}

.card.card-tabs .card-header .nav.nav-tabs li a {
  line-height: 1.3;
}

.card.card-tabs .card-header .nav.nav-tabs li:first-child a.active {
  border-radius: 0.25rem 0 0 0;
}

.nav .nav-item .nav-link.active,
.nav .nav-item .nav-link:active,
.nav .nav-item .nav-link:hover,
.nav-tabs.primary-tabs .nav-item .nav-link.active.show,
.nav-tabs.primary-tabs .nav-item .nav-link:hover,
.nav-tabs.primary-tabs .nav-item.show .nav-link {
  color: #68439a;
}

.nav-tabs.primary-tabs .nav-item .nav-link.active {
  border-bottom: solid 1px #68439a;
  color: #68439a;
}

.nav-tabs.primary-tabs .nav-item.show .nav-link,
.nav-tabs.primary-tabs .nav-link.active {
  border-color: #68439a;
}

.view-badge {
  vertical-align: 19%;
  margin-left: 10px;
  display: inline-block;
  padding: 4px 10px 4px;
}

/* custom tabs */

.primary-tabs .nav-item a.nav-link {
  min-height: 60px;
  padding: 12px 24px;
  text-transform: capitalize;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 2.25;
  letter-spacing: 0.02857em;
}

/* ********************************************************************************************
    Button
*********************************************************************************************** */

button,
.btn {
  outline: none !important;
}

.btn-primary,
a.btn-primary,
a:visited.btn-primary a:link.btn-primary .btn-primary.disabled,
.btn-primary:disabled {
  background-color: #68439a;
  border: 1px solid #68439a;
  color: rgba(255, 255, 255, 0.9);
}

.btn-primary:hover,
a.btn-primary:hover,
a:visited.btn-primary a:link.btn-primary:hover {
  background-color: #68439a !important;
  border: 1px solid #68439a !important;
  box-shadow: 0 3px 6px rgba(104, 67, 154, 0.3) !important;
  color: #fff;
}

.btn-primary:focus,
a.btn-primary:focus,
a:visited.btn-primary a:link.btn-primary:focus {
  background-color: #68439a;
  border: 1px solid #68439a;
  color: #fff !important;
  box-shadow: none !important;
}

.btn-primary:active,
a.btn-primary:active,
a:visited.btn-primary a:link.btn-primary:active {
  background-color: #68439a !important;
  border: 1px solid #68439a !important;
  color: #fff !important;
  box-shadow: none !important;
}

.btn-login-dark,
a.btn-login-dark,
a:visited.btn-login-dark a:link.btn-login-dark {
  background-color: #2e354f;
  border: 1px solid #2e354f;
  color: rgba(255, 255, 255, 0.9);
}

.btn-login-dark:hover,
a.btn-login-dark:hover,
a:visited.btn-login-dark a:link.btn-login-dark:hover {
  background-color: #2e354f !important;
  border: 1px solid #2e354f !important;
  box-shadow: 0 4px 8px rgba(46, 53, 79, 0.3) !important;
  color: #fff;
}

.btn-login-dark:focus,
a.btn-login-dark:focus,
a:visited.btn-login-dark a:link.btn-login-dark:focus {
  background-color: #2e354f;
  border: 1px solid #2e354f;
  color: #fff !important;
  box-shadow: none !important;
}

.btn-login-dark:active,
a.btn-login-dark:active,
a:visited.btn-login-dark a:link.btn-login-dark:active {
  background-color: #2e354f !important;
  border: 1px solid #2e354f !important;
  color: #fff !important;
  box-shadow: none !important;
}

.btn-info,
a:link.btn-info {
  color: rgba(255, 255, 255, 0.85) !important;
}

.btn-info.btn-outline,
a:link.btn-info.btn-outline {
  color: #399af2 !important;
}

.btn.btn-sm,
a:link.btn.btn-sm {
  padding: 0.5rem 0.75rem;
  height: auto;
}

.btn-icon-only {
  width: 30px !important;
  height: 25px !important;
  text-align: center !important;
  padding: 0px !important;
}

.btn-icon-only i {
  margin: 0 !important;
  padding: 0;
}

.btn-secondary:hover i,
.btn-secondary:focus i {
  color: #fff;
}

.dropdown .fa-sliders {
  margin-top: -5px;
}

.btn-primary2 {
  background-color: #6e85af !important;
  border-color: #6e85af;
  color: #fff;
}

.button-with-textbox,
.page-header .btn,
.page-header a:link.btn,
.removebtn {
  padding-top: 6px;
  padding-bottom: 6px;
  height: 35px;
}

.border-delete-icon {
  border: solid 1px #dc3545 !important;
  border-radius: 3px;
  line-height: 10px;
  padding: 6px 15px;
  margin: 0;
  color: #dc3545;
  background: transparent;
  cursor: pointer;
}

.border-delete-icon:hover {
  background-color: #dc3545;
  color: #fff;
}

/* ********************************************************************************************
    Navbar & Sidebar
*********************************************************************************************** */

.site-logo li .logo {
  top: 16px;
  left: 25px;
}

.site-logo li .brand-text {
  padding: 20px 0 0 32px;
}

.logo-custom {
  margin-top: 14px;
  margin-left: 25px;
}

.sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu > li > a {
  font-size: 0.9rem;
  font-weight: 400;
}

.sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .nav.metismenu
  > li
  > a.active {
  border-color: #68439a;
}

body
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .nav.metismenu
  > li.active
  > a,
body
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .nav.metismenu
  > li.active
  > a:focus,
body
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .nav.metismenu
  > li.active
  > a:hover {
  border-color: #68439a;
}

.sidebar.sidebar-left .sidebar-content {
  top: 65px;
  z-index: 9999;
}

body .top-toolbar.navbar-desktop {
  background: #2e354f;
}

body .aside-toolbar,
body .top-toolbar.navbar-mobile.navbar-tablet {
  background-color: #2e354f;
}

body .top-toolbar.navbar-desktop .navbar-nav .nav-item > a,
body .top-toolbar.navbar-desktop .navbar-nav .nav-item > a i {
  color: rgba(255, 255, 255, 0.95);
}

body .top-toolbar.navbar-desktop .navbar-nav .nav-item > a {
  font-weight: 500 !important;
}

.top-toolbar .dropdown-menu-md .dropdown-menu.dropdown-menu-left {
  width: 210px;
}

body .sidebar.sidebar-left .sidebar-content .main-menu .metismenu .open>a,
/* body .sidebar.sidebar-left .sidebar-content .main-menu .metismenu .open>a:focus, */
body .sidebar.sidebar-left .sidebar-content .main-menu .metismenu .open>a:hover,
body .sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu>li.active>a,
body .sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu>li.active>a>i,
body .sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu>li.nav-dropdown.active>ul>li.nav-dropdown.active>ul>li.active>a,
body .sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu>li.nav-dropdown>ul>li.nav-dropdown>ul>li>a:hover,
body .sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu>li>a:active,
body .sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu>li>a:hover {
  color: #68439a;
}

body
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .nav.metismenu
  li
  a:hover,
body
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .nav.metismenu
  li
  a:hover
  i {
  color: #68439a !important;
}
body
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .metismenu
  .open
  > a:focus {
  color: #718eb1;
}

body
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .nav.metismenu
  li
  a:hover {
  border-left: solid 3px #68439a;
}

body
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .nav.metismenu
  > .nav-dropdown.active
  > a {
  border-left: solid 3px #68439a;
}

body
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .nav.metismenu
  > .nav-dropdown.active
  > .nav-sub {
  background: none;
  border-left: solid 3px #68439a;
}

body
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .metismenu
  .nav-sub
  li
  a:hover,
body
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .metismenu
  > li
  > .nav-sub
  li
  a:hover {
  border: none;
}

.sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .nav.metismenu
  .nav-sub
  > li
  > a {
  font-size: 0.875rem;
  font-weight: 400;
}

body
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .nav.metismenu
  > li.active
  > a,
body
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .nav.metismenu
  > li.active
  > a:focus,
body
  .sidebar.sidebar-left
  .sidebar-content
  .main-menu
  .nav.metismenu
  > li.active
  > a:hover {
  color: #68439a;
  border-left: solid 3px #68439a;
}

body .top-toolbar {
  right: 0;
  left: 0;
  position: fixed;
}

.sidebar-left {
  position: fixed !important;
  height: 100% !important;
  /* margin-top: 65px; */
}

body .sidebar-left,
body .sidebar-left .sidebar-content {
  background-color: #fff;
  /* overflow: auto; */
}

body .sidebar-left ~ .content-wrapper .content {
  padding-top: 65px;
  margin-left: 255px;
}

body .content {
  padding-top: 65px;
}

.mCSB_inside > .mCSB_container {
  margin-right: 15px;
}

.sidebar.sidebar-left .sidebar-content .main-menu {
  max-height: calc(100vh - 65px);
  padding-bottom: 10px;
}

.sidebar.sidebar-left .sidebar-content .main-menu .nav.metismenu {
  overflow-y: hidden;
}

/* ********************************************************************************************
    Table
*********************************************************************************************** */

.table-hover tbody tr:hover {
  background-color: rgba(231, 241, 253, 0.2);
}

.table tbody th {
  font-weight: 500;
}

.table td,
.table th {
  border-top: none;
}

.table td,
.table th,
.table thead th {
  vertical-align: middle;
}

.table-field-status .dropdown .dropdown-menu .dropdown-item i {
  vertical-align: inherit;
  font-size: 11px;
  margin-right: 5px;
}

.table-field-actions,
.table-field-status {
  width: 120px !important;
  text-align: center;
}

.table-field-actions .dropdown-menu,
.table-field-status .dropdown-menu {
  width: 150px;
}

.table-field-actions .dropdown .dropdown-menu .dropdown-item {
  padding: 10px;
}

.table tr td:first-child,
.table tr th:first-child {
  padding-left: 1.25rem;
}

.remove-padding.table td:first-child,
.remove-padding.table th:first-child {
  padding-left: 0 !important;
}

.remove-padding.table td:last-child,
.remove-padding.table th:last-child {
  padding-right: 0 !important;
}

.table-small-padding.table > tbody > tr > th,
.table-small-padding.table > tbody > tr > td {
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 13px;
  font-weight: normal;
}

.table > thead > tr > th > a {
  position: relative;
  color: #617182;
}

.table > thead > tr > th > a:before,
.table > thead > tr > th > a:after {
  position: absolute;
  display: block;
  opacity: 0.5;
  bottom: -0.15em;
  margin-right: -25px;
}

.table > thead > tr > th > a:before {
  content: "\2193";
  right: 0em;
}

.table > thead > tr > th > a:after {
  content: "\2191";
  right: 0.5em;
}

.table > thead > tr > th.descending > a:before,
.table > thead > tr > th.ascending > a:after {
  opacity: 1;
}

.table > thead > tr > th button {
  background: transparent;
  border: 0;
  color: #5accca;
  width: 100%;
  text-align: left;
  cursor: pointer;
}

.table > thead > tr > th button .caret {
  float: right;
}

.table > thead > tr > th button .caret:before {
  font: normal normal normal 14px/1 LineAwesome;
  display: block;
  font-size: 18px;
  margin-top: 2px;
  width: 100%;
  color: #718eb1;
  float: right;
}

.table > thead > tr > th button.desc .caret:before {
  content: "\f308";
}

.table > thead > tr > th button.asc .caret:before {
  content: "\f307";
}

.table > thead > tr > th button:focus {
  outline: none;
}

/* ********************************************************************************************
    Dropdown
*********************************************************************************************** */

.dropdown-notifications a {
  position: relative;
}

.dropdown-notifications a i.circle-dot {
  position: absolute !important;
  right: 18px !important;
  top: 8px !important;
  font-size: 10px !important;
  color: #ef606d !important;
}

.dropdown.filters .dropdown-menu,
.dropdown300 {
  width: 300px !important;
}

.dropdown-menu #filter-form .fa-times {
  font-size: 14px;
  margin-top: -3px;
}

.dropdown-menu #filter-form .btn-link {
  padding-left: 0 !important;
  line-height: 20px;
}

.dropdown .dropdown-menu .dropdown-item.active,
.dropdown .dropdown-menu .dropdown-item:active,
.dropdown .dropdown-menu .dropdown-item:focus,
.dropdown .dropdown-menu .dropdown-item:hover,
.dropleft .dropdown-menu .dropdown-item.active,
.dropleft .dropdown-menu .dropdown-item:active,
.dropleft .dropdown-menu .dropdown-item:focus,
.dropleft .dropdown-menu .dropdown-item:hover,
.dropright .dropdown-menu .dropdown-item.active,
.dropright .dropdown-menu .dropdown-item:active,
.dropright .dropdown-menu .dropdown-item:focus,
.dropright .dropdown-menu .dropdown-item:hover,
.dropup .dropdown-menu .dropdown-item.active,
.dropup .dropdown-menu .dropdown-item:active,
.dropup .dropdown-menu .dropdown-item:focus,
.dropup .dropdown-menu .dropdown-item:hover {
  color: #68439a !important;
}

.dropdown .dropdown-menu .dropdown-item.active i,
.dropdown .dropdown-menu .dropdown-item:active i,
.dropdown .dropdown-menu .dropdown-item:focus i,
.dropdown .dropdown-menu .dropdown-item:hover i,
.dropleft .dropdown-menu .dropdown-item.active i,
.dropleft .dropdown-menu .dropdown-item:active i,
.dropleft .dropdown-menu .dropdown-item:focus i,
.dropleft .dropdown-menu .dropdown-item:hover i,
.dropright .dropdown-menu .dropdown-item.active i,
.dropright .dropdown-menu .dropdown-item:active i,
.dropright .dropdown-menu .dropdown-item:focus i,
.dropright .dropdown-menu .dropdown-item:hover i,
.dropup .dropdown-menu .dropdown-item.active i,
.dropup .dropdown-menu .dropdown-item:active i,
.dropup .dropdown-menu .dropdown-item:focus i,
.dropup .dropdown-menu .dropdown-item:hover i {
  color: #68439a;
}

.btn-group .dropdown-menu {
  margin: 5px 0 0;
  border: none;
  box-shadow: 0 4px 14px rgba(174, 197, 231, 0.5);
  font-size: 0.875rem;
  z-index: 1000;
}

.btn-group a i {
  font-size: 1.375rem !important;
  vertical-align: middle !important;
}

.btn-group .dropdown-menu .dropdown-item {
  display: block;
  width: 100%;
  clear: both;
  font-weight: 400;
  text-align: inherit;
  white-space: nowrap;
  border: 0;
  padding: 10px 20px;
  font-size: 0.875rem;
  border-left: solid 2px transparent;
  position: relative;
}

.btn-group .dropdown-menu .dropdown-item {
  color: #839bb3;
}

.btn-group .dropdown-item i {
  font-size: 0.9375rem !important;
  margin-right: 0.25rem;
}

.btn-group .dropdown-item.active,
.btn-group .dropdown-item:active,
.btn-group .dropdown-item:hover {
  color: #68439a;
  text-decoration: none;
  background-color: rgba(231, 241, 253, 0.2);
}

.btn-group .dropdown-toggle::after {
  display: none;
}

.btn-group .dropdown-toggle.btn-primary {
  background-color: transparent !important;
  border: none !important;
}

.btn-group .dropdown-toggle.btn-primary:hover {
  box-shadow: none !important;
}

.btn-group .dropdown-toggle.btn-primary i {
  font-size: 24px !important;
}

.btn-group .dropdown-menu {
  top: auto !important;
  animation-name: none;
}

.btn-group .dropdown-menu .dropdown-item {
  color: #839bb3;
}

.btn-group .dropdown-item i {
  margin-right: 0.25rem;
  vertical-align: -1px !important;
}

.btn-group .dropdown-item.active,
.btn-group .dropdown-item:active,
.btn-group .dropdown-item:hover {
  color: #f7b500;
  text-decoration: none;
  background-color: rgba(231, 241, 253, 0.2);
}

body .top-toolbar.navbar-desktop .dropdown-menu ul li:hover a {
  color: #f7b500;
}

/* ********************************************************************************************
    Form
*********************************************************************************************** */

.left-form-content {
  margin-right: 40px;
  margin-left: 20px;
}

.fileinput .thumbnail > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.upload-img-label {
  font-size: 11px;
  margin-bottom: 0;
  font-weight: normal;
}

.uploaded-user-image .upload-icon {
  position: absolute;
  bottom: 15px;
  left: 10px;
}

.label {
  font-weight: 600;
}

label em,
.control-label em {
  font-style: normal;
  color: #ff0026;
  font-weight: normal;
  font-family: arial;
}

.form-row {
  margin-left: -7.5px;
  margin-right: -7.5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-left: 7.5px;
  padding-right: 7.5px;
}

.form-control:focus {
  border: 1px solid rgb(104 67 154 / 65%);
}

/* Select2 */
.input-group
  .select2-container--default:first-of-type
  .select2-selection--single {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice__remove {
  font-family: Arial, Helvetica, sans-serif;
}

.select2-container--default .select2-selection--multiple,
.select2-container--default .select2-selection--single {
  min-height: 35px;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  /*padding: 0.49rem 1rem;*/
  color: #323a48;
  position: relative;
  padding: 0.5rem 0.75rem;
  line-height: 1.25;
  font-size: 0.875rem;
  font-weight: normal;
}

.select2-container--bootstrap.select2-container--focus .select2-selection,
.select2-container--bootstrap.select2-container--open .select2-selection {
  border: 1px solid #736cc7;
  box-shadow: none;
}

/* Checkbox & Radio */
.custom-control-label {
  cursor: pointer;
}

.control.control-outline.control-primary input:checked ~ .control__indicator {
  border: 1px solid #68439a;
}

.control.control-outline.control-primary.control--checkbox
  .control__indicator:after {
  border-color: #68439a;
}

.bootstrap-timepicker-widget table td input {
  border-width: 1px;
}

.css-b62m3t-container .css-1okebmr-indicatorSeparator,
.form-group .css-1okebmr-indicatorSeparator {
  background-color: #dfe7f3;
}

.css-b62m3t-container path,
.form-group svg.css-tj5bde-Svg path {
  fill: #dfe7f3;
}

.react-tel-input {
  margin-bottom: 1rem;
}

.react-tel-input .form-control {
  width: 100%;
  border-color: #dfe7f3;
}

.react-tel-input .flag-dropdown {
  border-color: #dfe7f3;
  background-color: #dfe7f3;
}

.ueser-img150 {
  width: 150px;
  height: 150px;
  border-radius: 100%;
  border: solid 1px #dfe7f3;
  background-color: #f0f0f0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ueser-img150 p {
  margin: 0;
}

.ueser-img150 img {
  background-size: contain;
  width: 110px !important;
  height: 110px !important;
  object-fit: contain;
}

.attachment-img {
  display: inline-block;
  border: solid 1px #dfe7f3;
  padding: 3px;
  position: relative;
}

.attachment-img > img {
  height: 70px;
  width: 70px;
  object-fit: cover;
  /* display: inline-block;
    margin-right: 20px;
    margin-top: 15px;
    margin-bottom: 10px;
    /*height: 100px !important;*/
  /* border: solid 1px #dfe7f3; */
  /*padding: 3px;
    position: relative;*/
}

.attachment-img .btns {
  position: absolute;
  border: 0;
  /* top: -15px;
    right: -10px; */
  top: -15px;
  right: -10px;

  border-radius: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px;
  cursor: pointer;
}

.attachment-img .btns svg {
  width: 16px;
}
.attachment-img-container {
  margin-top: 15px;
  margin-right: 15px;
  margin-bottom: 10px;
}
.attachment-img-container .btns {
  border: none;
  vertical-align: top;
  margin-left: 10px;
  padding: 8px;
  line-height: 10px;
  cursor: pointer;
}
.attachment-img-container .btns svg {
  height: 15px;
  width: 15px;
}
.delete-btn svg {
  height: 15px;
  width: 15px;
}

.attachment-doc {
  display: inline-block;
  margin-right: 20px;
  /* margin-top: 15px; */
  /* margin-bottom: 10px; */
  height: 100px !important;
  /* border: solid 1px #dfe7f3; */
  padding: 3px;
  position: relative;
}

.attachment-doc .btns {
  position: absolute;
  border: 0;
  top: -15px;
  right: -10px;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px;
  cursor: pointer;
}

.attachment-doc .btns svg {
  width: 16px;
}

/* ********************************************************************************************
    Login
*********************************************************************************************** */

.sign-in-form {
  margin: 10% auto;
}

.login-box,
.register-box {
  width: 100%;
  max-width: 410px;
  box-shadow:
    0 10px 40px 0 rgba(18, 106, 211, 0.07),
    0 2px 9px 0 rgba(18, 106, 211, 0.06);
  border-radius: 10px;
  margin: 5% auto;
}

.login-box.custom-login-box {
  max-width: 610px !important;
}

.login-logo,
.register-logo {
  background: #2e354f;
  margin-bottom: 0;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  padding-top: 20px;
  padding-bottom: 25px;
  text-align: center;
}

.login-box-msg,
.register-box-msg {
  font-weight: normal;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 30px;
  text-align: center;
}

.login-box-body,
.register-box-body {
  color: inherit;
  padding: 30px 25px;
  background: #fff;
}

.login-box-body .form-control {
  background-color: #f0f6ff;
  border-color: transparent;
  font-size: 15px;
  border-radius: 50px;
  height: 49px;
  text-align: center;
}

.login-box-body .form-control:focus {
  background-color: #fff;
  border-color: rgba(47, 46, 68, 0.7);
}

.login-box-body .btn {
  font-size: 15px;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  border-radius: 50px;
  margin-top: 5px;
}

.login-box-footer {
  background-color: #f0f6ff;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  /* border-top: 1px solid rgba(137, 142, 148, 0.7); */
  padding: 20px 25px 5px;
  font-size: 14px;
}

.login-box-body textarea.form-control {
  border-radius: 7px !important;
  height: inherit !important;
}

.main-container {
  position: relative;
  min-height: 100vh;
}

/* **************************************************************************************
    Modal
*************************************************************************************** */

.modal-dialog {
  height: 100%;
  margin: 0px auto;
  padding: 60px 0px;
  z-index: 9999;
}

.modal-dialog > form {
  height: 100%;
}

.modal-content {
  border-radius: 3px;
  /*max-height: 100%;*/
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.modal-header,
.modal-body,
.modal-footer {
  padding: 15px 20px;
}

.modal-header {
  min-height: 60px;
}

.modal-footer {
  min-height: 70px;
}

.modal-header .close {
  margin-top: -6px;
  font-size: 25px;
  font-family: arial;
  padding: 0;
  padding-right: 1rem;
  padding-top: 0.3rem;
}

.modal-title {
  font-weight: 500;
}

.modal-body {
  max-height: 400px;
  overflow-y: auto;
}

.modal-footer {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

/* ********************************************************************************************
    Chart
*********************************************************************************************** */

.pie-chart-legend .table {
  font-size: 16px;
  margin: 0px auto;
  max-width: 340px;
  white-space: nowrap;
}

.pie-chart-legend .table .colorbox,
.chart-legend ul > li > span {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  display: inline-block;
  vertical-align: 0px;
  margin-right: 10px;
}

.pie-chart-legend .table .value {
  font-weight: 600;
  color: #525e70;
}

.chart-legend ul {
  list-style: none;
  font-size: 16px;
  padding: 0px;
  margin: 0px;
  float: right;
}

.chart-legend ul li {
  display: inline-block;
  margin-left: 20px;
}

.chart-legend ul > li > span {
  margin-right: 10px;
}

/* ********************************************************************************************
    Rating
*********************************************************************************************** */

.rating-container {
  margin: 0 auto;
}

.rating-container {
  width: 100px;
  height: 19px;
  font-size: 0;
  line-height: 0;
  background: url(../../assets/img/ratings_large.png) 0 0 repeat-x;
  text-indent: -999em;
  overflow: hidden;
  background-size: 20px;
}

.rating-container .rating-stars {
  /*cursor: pointer;*/
  float: left;
  height: 19px;
  background: url(../../assets/img/ratings_large.png) 0 100% repeat-x;
  background-size: 20px;
}

.rating-sm.rating-container {
  width: 80px;
  height: 17px;
  font-size: 0;
  line-height: 0;
  background: url(../../assets/img/ratings_large.png) 0 0 repeat-x;
  text-indent: -999em;
  overflow: hidden;
  background-size: 16px;
}

.rating-sm.rating-container .rating-stars {
  /*cursor: pointer;*/
  float: left;
  height: 15px;
  background: url(../../assets/img/ratings_large.png) 0 100% repeat-x;
  background-size: 16px;
}

.rating-lg.rating-container {
  width: 175px;
  height: 34px;
  font-size: 0;
  line-height: 0;
  background: url(../../assets/img/ratings_large.png) 0 0 repeat-x;
  text-indent: -999em;
  overflow: hidden;
  background-size: 35px;
}

.rating-lg.rating-container .rating-stars {
  /*cursor: pointer;*/
  float: left;
  height: 34px;
  background: url(../../assets/img/ratings_large.png) 0 100% repeat-x;
  background-size: 35px;
}

/*New Rating Star*/
.my-ratings .rating-container .rating-stars {
  /*cursor: pointer;*/
  float: left;
  height: 34px;
  background: url(../../assets/img/ratings_large.png) 0 100% repeat-x;
  background-size: 35px;
}

.my-ratings .rating-container {
  width: 175px;
  height: 36px;
  font-size: 0;
  line-height: 0;
  background: url(../../assets/img/ratings_large.png) 0 0 repeat-x;
  text-indent: -999em;
  overflow: hidden;
  background-size: 35px;
  margin: 33px auto 35px;
}

.rating-lg .rating-gly-star,
.rating-lg .rating-gly-star .rating-stars:before {
  padding-left: 0px !important;
}

.my-ratings .rating-container .rating-stars {
  cursor: pointer !important;
}

.rating-container .rating-stars {
  cursor: inherit !important;
}

/* ********************************************************************************************
    Map
*********************************************************************************************** */

#map_wrapper {
  height: 400px;
}

#map_canvas {
  width: 100%;
  height: 100%;
}

.location-block {
  margin-bottom: 20px;
}

.location-content {
  font-size: 14px;
  font-weight: 500;
}

.location-head {
  margin-bottom: 20px;
}

.location-icon {
  font-size: 18px;
  color: #023981;
  padding-right: 6px;
}

.location-title {
  font-weight: 600;
  color: #023981;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.info_content {
  background-color: #fff;
  padding: 10px;
}

.info_location_name {
  color: #023981;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.info_location_text {
  margin-bottom: 10px;
  color: #767a7f;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
}

.info_location_call {
  font-size: 14px;
  color: #2e3133;
  font-weight: 600;
}

.info_location_call span {
  padding-right: 8px;
  font-size: 14px;
  color: #023981;
}

.mt30 {
  margin-top: 20px;
}

/* ********************************************************************************************
    React Select Dropdown
*********************************************************************************************** */
.custom-select-dropdown,
.react-select-dropdown__option {
  cursor: pointer;
}

.custom-select-dropdown span + span + div {
  border-color: #dfe7f3 !important;
  height: inherit;
  min-height: 35px;
  max-height: auto;
  box-shadow: none;
  font-size: 15px;
  cursor: pointer;
}

.custom-select-dropdown span + span + div:hover {
  border-color: #f7b500;
}

.custom-select-dropdown span + span + div div span {
  display: none;
}

.custom-select-dropdown span + span + div div span + div {
  height: 15px;
  width: 15px;
  padding: 0;
  margin-right: 10px;
  margin-top: -4px;
}

.custom-select-dropdown span + span + div div span + div svg {
  color: #617182;
}

.custom-select-dropdown span + span + div > div > div {
  font-size: 0.875rem;
  margin-left: 5px;
  color: #617182;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.custom-select-dropdown span + span + div + div {
  font-size: 0.875rem;
  border: 1px solid #dfe7f3;
  box-shadow: 0 4px 14px rgba(174, 197, 231, 0.5);
}

.custom-select-dropdown span + span + div input {
  height: 20px;
}

.react-select-dropdown__option:hover {
  color: black;
  background-color: rgba(231, 241, 253, 0.2) !important;
}

.react-select-dropdown__option.react-select-dropdown__option--is-selected {
  background-color: #68439a;
}

.custom-select-dropdown.multi span + span + div + div {
  border: none;
  box-shadow: none;
}

/* ********************************************************************************************
    Autocomplete
*********************************************************************************************** */

.autocomplete-suggestions {
  border: 1px solid rgba(120, 141, 180, 0.3);
  background: #fff;
  cursor: default;
  overflow: auto;
}

.autocomplete-suggestion {
  padding: 3px 10px;
}

.autocomplete-suggestion:hover {
  background: #5accca;
  cursor: pointer;
  color: #fff;
}

/* ********************************************************************************************
    Accordion
*********************************************************************************************** */

.accordion > .card > .card-header .btn-link {
  padding: 6px 125px 6px 20px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.accordion > .card > .card-header .btn-link:after {
  right: 8.5%;
  top: 50%;
}

.assign-timeslot.accordion > .card > .card-header .btn-link {
  padding: 6px 20px 6px 20px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.assign-timeslot.accordion > .card > .card-header .btn-link:after {
  right: 2.5%;
  top: 50%;
}

/* ********************************************************************************************
    Others
*********************************************************************************************** */
.form-horizontal .form-group .control-label,
.form-horizontal .control-label {
  margin-top: 7px;
}

.user-list li .user-name {
  margin-left: 15px;
  font-weight: 500;
}

.cms-page p {
  font-size: 15px;
}

.cms-page ul li,
.cms-page ol li {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 10px;
}

.text-underline {
  text-decoration: underline !important;
}

.has-icon {
  position: relative;
}

.password-icon {
  position: absolute;
  top: 14px;
  transform: translateY(50%);
  right: 0px;
  width: 60px;
  height: 35px;
  line-height: 34px;
  text-align: center;
  border-left: 1px solid #d8e3ef;
}

.form-control .fileinput-filename {
  width: 240px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/*multiselect-native-select*/
.multiselect-native-select .btn {
  background: transparent;
  border: 1px solid #dfe7f3;
  width: 100%;
}

.multiselect-native-select .btn-group,
.btn-group-vertical {
  display: inline;
}

.multiselect-native-select .glyphicon-search:before {
  content: "\f1c3";
  font: normal normal normal 16px/1 Material-Design-Iconic-Font;
  font-weight: 700;
}

.multiselect-native-select .glyphicon-remove-circle:before {
  content: "\f134";
  font: normal normal normal 16px/1 Material-Design-Iconic-Font;
  font-weight: 700;
}

.multiselect-container > li > a {
  color: #617182 !important;
}

.multiselect-container .input-group {
  width: auto;
}

.multiselect-native-select .btn-group .dropdown-menu {
  height: 260px;
  overflow-y: auto;
  overflow-x: hidden;
}

.multiselect-native-select .btn-group .dropdown-toggle::after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  color: #888;
}

.multiselect-native-select .btn {
  height: 35px;
  text-align: left;
  padding: 4px 20px;
}

.multiselect-native-select .multiselect-selected-text {
  color: #323a48;
  font-weight: 400;
}

/* Theme Select */
.select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: #68439a;
}

.select2-container--default.select2-container--focus .select2-selection--single,
.select2-container--default.select2-container--open
  .select2-selection--multiple,
.select2-container--default.select2-container--open .select2-selection--single {
  border-color: #68439a;
}

/* Select2 */
.select2-container .select2-selection--single {
  height: 35px;
}

.select2-container--krajee.select2-container
  .select2-selection--single
  .select2-selection__rendered {
  margin-top: -6px;
  margin-left: -18px;
}

.select2-container--krajee
  .select2-selection--single
  .select2-selection__placeholder {
  display: block;
  margin-left: 10px;
  margin-top: 6px;
}

.select2-container--krajee .select2-selection {
  border: 1px solid #dfe7f3;
  box-shadow: none;
}

.select2-container--krajee
  .select2-selection--single
  .select2-selection__arrow {
  border-left: none;
}

.select2-container--krajee
  .select2-selection--single
  .select2-selection__placeholder {
  color: #868585;
}

.has-success .select2-container--open .select2-selection,
.has-success
  .select2-container--krajee.select2-container--focus
  .select2-selection {
  box-shadow: none !important;
  border-color: #dfe7f3 !important;
  outline: none !important;
  /* border: none; */
}

.select2-container--krajee.select2-container--open .select2-selection,
.select2-container--krajee .select2-selection:focus {
  box-shadow: none !important;
  border-color: rgb(27 197 0 / 0.7) !important;
  outline: none !important;
}

.select2-container--krajee
  .select2-results__option--highlighted[aria-selected] {
  background-color: rgb(27 197 0 / 0.7);
}

.select2-container--krajee .select2-results__option[aria-selected="true"] {
  color: #fff;
  background-color: rgb(27 197 0 / 0.7);
}

.select2-container--krajee
  .select2-selection--multiple
  .select2-selection__choice {
  margin: 7px 0 0 6px;
}

.select2-container--krajee.select2-container .select2-selection--multiple {
  min-height: 35px;
}

.select2-container--krajee
  .select2-selection--multiple
  .select2-selection__clear {
  top: 50%;
  transform: translateY(-50%);
}

.has-success.select2-container--krajee .select2-dropdown,
.has-success .select2-container--krajee .select2-selection {
  border-color: #dfe7f3;
}

/* Fileinput */
.form-control .fileinput-filename {
  width: 240px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* Pagination */
.card-body .pag > .row > .col .pagination,
.pagination {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-end !important;
  margin: 0;
  padding: 1rem;
}

.pagination li.active a {
  z-index: 0;
  color: #fff;
  background-color: #1bc500;
  border-color: #1bc500;
  border-radius: 100%;
  box-shadow: none;
  outline: 0;
  width: 30px;
  height: 30px;
  text-align: center;
}

/* .pagination li a,
.pagination li span, */
.pagination .page-item.active .page-link {
  width: auto;
  height: 30px;
  line-height: 31px !important;
  text-align: center;
  display: block;
  min-width: 30px;
  margin-top: -3px;
}

.pagination .page-item.active .page-link {
  background-color: #68439a;
  border-color: #68439a;
  padding: 0 6px !important;
}

/* .page-link {
    padding: 0px !important;
} */
.page-item.previous a,
.page-item.next a {
  width: auto !important;
  padding: 0px 10px !important;
}

.pagination a:hover {
  color: #68439a;
}

.pagination li a {
  padding: 0.5rem 0.75rem;
  text-transform: capitalize;
}

/* Others */
body #app .content-wrapper {
  overflow-x: hidden;
}

.maintenance-icons li i {
  color: #59667a;
  font-size: 50px;
  position: relative;
  animation: rotate-effect 40s linear infinite reverse;
}

.maintenance-icons li:nth-child(2) i {
  font-size: 80px;
  top: -15px;
  right: -60px;
  animation: rotate-effect 40s linear infinite reverse;
}

.maintenance-icons li:nth-child(3) i {
  animation: rotate-effect 30s linear infinite;
  font-size: 110px;
  top: -85px;
  right: 60px;
}

.mt-n-75 {
  margin-top: -75px;
}

.lh-0 {
  line-height: 0px;
}

.ml-21 {
  margin-left: 21px;
}

.text-error {
  font-size: 13px;
  color: #ff5c75 !important;
  font-weight: 500;
  margin-top: 0.25rem;
}

.MuiTabs-root .MuiButtonBase-root {
  min-height: 60px;
  padding: 12px 24px;
  text-transform: capitalize;
}

.MuiTouchRipple-root {
  display: none;
}

.rdw-editor-toolbar {
  border: none;
  border-bottom: 1px solid #dfe7f3;
}

.comments-media .media:not(:last-child) {
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid #eaf3fd !important;
}

.comments-media .MuiAvatar-root {
  height: 35px;
  width: 35px;
  line-height: 35px !important;
  font-size: 1.125rem;
}

.MuiSwitch-switchBase.Mui-checked {
  color: #68439a !important;
}

.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
  background-color: #68439a !important;
}

.editor-state-change {
  width: 93%;
}

/* ********************************************************************************************
    Design for a width of 768px and above
*********************************************************************************************** */

@media only screen and (min-width: 768px) {
  /* SM - Floating
    ======================================================================================= */
  .pull-sm-left {
    float: left !important;
  }

  .pull-sm-right {
    float: right !important;
  }

  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }
}

/* ********************************************************************************************
    Design for a width of 992px and above
*********************************************************************************************** */

@media only screen and (min-width: 992px) {
  /* MD - Floating
    ======================================================================================= */

  .pull-md-left {
    float: left !important;
  }

  .pull-md-right {
    float: right !important;
  }

  .text-sm-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }
  .editor-state-change {
    width: 90%;
  }
}

/* ********************************************************************************************
    Design for a width of 1200px and above
*********************************************************************************************** */

@media only screen and (min-width: 1200px) {
}

/* ********************************************************************************************
    Design for a width of 1400px and above
*********************************************************************************************** */

@media only screen and (min-width: 1380px) {
}

/* ********************************************************************************************
    Design for a width of 1400px and above
*********************************************************************************************** */

@media screen and (min-width: 1400px) and (max-width: 1920px) {
}

/* ********************************************************************************************
    Design for a width of 992px and below
*********************************************************************************************** */

@media only screen and (min-width: 992px) and (max-width: 1199px) {
}

/* ********************************************************************************************
    Design for a width of 992px and below
*********************************************************************************************** */

@media only screen and (min-width: 768px) and (max-width: 991px) {
}

/* ********************************************************************************************
    Design for a width of 1024px and below
*********************************************************************************************** */

@media only screen and (max-width: 1024px) {
}

/* ********************************************************************************************
    Design for a width of 991px and below
*********************************************************************************************** */

@media only screen and (max-width: 991px) {
  body .sidebar-left ~ .content-wrapper .content {
    margin-left: 0px;
  }

  .top-toolbar {
    border-bottom: 1px solid rgb(255 255 255 / 20%);
  }
}

/* ********************************************************************************************
    Design for a width of 767px and below
*********************************************************************************************** */

@media only screen and (max-width: 767px) {
  .m-b-90 {
    margin-bottom: 40px;
  }

  .tab-pane .actions {
    position: relative !important;
  }

  .tab-pane .actions.top-right {
    right: 0px !important;
  }

  body .sidebar-left ~ .content-wrapper .content {
    margin-left: 0px !important;
  }

  .MuiTabs-scroller {
    overflow-x: auto !important;
  }

  .MuiTabs-root .MuiButtonBase-root {
    padding: 12px 15px !important;
  }
}

/* ********************************************************************************************
    Design for a width of 576px and below
*********************************************************************************************** */

@media only screen and (max-width: 576px) {
  .d-flex,
  .media {
    flex-wrap: wrap !important;
  }

  .pagination {
    flex-wrap: wrap !important;
    margin-left: -13px !important;
    margin-top: 10px !important;
  }

  .d-flex.align-items-center .m-l-10 {
    margin-bottom: 8px !important;
  }

  .actions.top-right li {
    margin-bottom: 8px !important;
  }

  .actions.top-right .m-l-10 .input-group,
  .actions.top-right .ml-0 .input-group {
    width: 270px !important;
  }

  .left-form-content {
    width: 100%;
    float: none;
    text-align: center;
  }

  .accordion > .card > .card-header .btn-link {
    padding: 6px 32px 5px 20px;
  }

  .accordion > .card > .card-header {
    height: 70px !important;
    white-space: normal !important;
  }
}

/* ********************************************************************************************
    Design for a width of 520px and below
*********************************************************************************************** */

@media only screen and (max-width: 520px) {
}

/* ********************************************************************************************
    Design for a width of 520px and below
*********************************************************************************************** */

@media only screen and (min-width: 480px) and (max-width: 520px) {
}

/* ********************************************************************************************
    Design for a width of 479px and below
*********************************************************************************************** */

@media only screen and (max-width: 479px) {
}

@media only screen and (max-width: 410px) {
  .site-logo li .brand-text {
    padding: 20px 0 0 0px;
  }

  .input-group.mb-3 {
    width: 250px !important;
  }

  .accordion > .card > .card-header {
    height: 90px !important;
    white-space: normal !important;
  }

  .accordion > .card > .card-header .btn-link {
    height: 90px !important;
    white-space: normal !important;
  }
}

/* ********************************************************************************************
    Design for a width of 380px and below
*********************************************************************************************** */

@media only screen and (max-width: 380px) {
}

/* ********************************************************************************************
    Design for a width of 320px and below
*********************************************************************************************** */

@media only screen and (max-width: 320px) {
}

.first-char-Capital {
  text-transform: capitalize;
}

.text-end {
  text-align: end;
}

.nav-wrapper {
  margin-bottom: 15px;
}

.sl-nav {
  display: flex;
}

.sl-nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  display: inline-block;
}

.sl-nav li {
  cursor: pointer;
}

.sl-nav li ul {
  display: none;
}

.sl-nav li:hover ul {
  position: absolute;
  top: 40px;
  right: 0px;
  display: block;
  background: #fff;
  width: 150px;
  padding-top: 0px;
  z-index: 1;
  border-radius: 5px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

.sl-nav li:hover .triangle {
  position: absolute;
  top: 26px;
  right: 5px;
  z-index: 10;
  height: 14px;
  overflow: hidden;
  width: 30px;
  background: transparent;
}

.sl-nav li:hover .triangle:after {
  content: "";
  display: block;
  z-index: 20;
  width: 15px;
  transform: rotate(45deg) translateY(0px) translatex(10px);
  height: 15px;
  background: #fff;
  border-radius: 2px 0px 0px 0px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

.sl-nav li ul li {
  position: relative;
  text-align: left;
  background: transparent;
  padding: 15px 15px;
  padding-bottom: 0;
  z-index: 2;
  font-size: 15px;
  color: #3c3c3c;
  line-height: 30px;
}

.sl-nav li ul li:last-of-type {
  padding-bottom: 15px;
}

.sl-nav li ul li span {
  padding-left: 5px;
}

.sl-nav li ul li span:hover,
.sl-nav li ul li span.active {
  color: #146c78;
}

.sl-flag {
  display: inline-block;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);
  width: 15px;
  height: 15px;
  background: #aaa;
  border-radius: 50%;
  position: relative;
  top: 2px;
  overflow: hidden;
}

.flag-de {
  background: url("https://www.spintrak.com/wp-content/uploads/2019/03/flag-400.png");
  background-size: cover;
  background-position: center center;
}

.flag-usa {
  background-size: cover;
  background-position: center center;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAZdEVYdFNvZnR3YXJlAHBhaW50Lm5ldCA0LjAuMTM0A1t6AAABhUlEQVQ4T2Ows82PjGixsc4LD2tysC/09Kjw8622tyuICG8u0w/cpGSCBzF4e1VmZkzw9anOzOj38a4KCW4IC22ECHYk1l9tn4gHMeTlTnZxLikvm+XiUpKW2hvgX+vnV5OVOQEoOGfOtv94AYOzU3Fd7XxHh6Lq6rlurqUx0W0J8Z1AnbW18yotonaYuOJBDBXls4A+bGpaBCTz86YEBtQCvVBSPAPIbY0oP1/aiAcxABU1Ny+2tclvbFjo5FgUF9uenNwNDLnmpkWEnV1TPRcY1O1tS4H6i4umA/0MDK2K8tlAwRqHpP1uoXgQKKraWpcClTY3LQZaCLQ5NaUX5OaWJY3++SeTC/AgBmA4AXUClUJs9ver8fKsAAYEUJCws4G21dXNB1oFdD/Qz8DQTk4C+bm2dn6DZ9bRiDQ8iAEYt8CoBpK5YBIYw0AEEZwSXX4oMB4PYoC6gCzAcDqrjGzEsMfen2xEmbMv1rSTjRi26dqRjShz9o2+6WQjBrSShQSkZAIADvW/HLrLY6cAAAAASUVORK5CYII=");
}

.print-container {
  font-family: Verdana sans-serif;
  font-size: 1.0625rem;
  line-height: 1.75rem;
  font-weight: 300;
  color: #5b6a77;
  margin: 0 auto;
  margin-top: 0rem;
  width: 410mm;
  padding: 20px;
}

.course-applied tr td {
  border: solid 1px #000;
  padding: 10px 20px;
  line-height: normal;
}

.course-applied .align-top {
  vertical-align: top;
}

.name-line {
  border-bottom: solid 1px #000;
}

.check-line-box {
  border: solid 1px #000;
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-right: 5px;
}

.line-table tr td {
  padding-top: 10px;
  padding-bottom: 10px;
  vertical-align: top;
}

.image {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
  cursor: pointer;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 70%;
  left: 40%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.img-con:hover .image {
  opacity: 0.3;
}

.img-con:hover .middle {
  opacity: 1;
}

.text {
  background-color: transparent;
  color: black;
  font-size: 16px;
  padding: 16px 32px;
}

.userSelection-bg {
  background-color: #eae2f2;
}

.c-p {
  cursor: pointer;
}

.pag > .row > .col > .pagination {
  /* margin-left: 35px !important; */
  justify-content: start !important;
}

.equal-height {
  height: 100%;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded {
  margin: 0 !important;
}

.css-viou3o-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
  padding: 0 !important;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  -webkit-transform: translate(14px, 10px) scale(1) !important;
  -moz-transform: translate(14px, 10px) scale(1) !important;
  -ms-transform: translate(14px, 10px) scale(1) !important;
  transform: translate(14px, 10px) scale(1) !important;
}
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  font-size: 15px !important;
  font-weight: normal !important;
  font-family: inherit !important;
  color: #6f6f6f !important;
}
.react-viewer-list > li {
  width: 70px !important;
}
.react-viewer-list > li > img {
  width: 100px !important;
  height: 50px !important;
  margin-left: -15px !important;
  /* opacity: 0.5; */
}

.progress-hide {
  display: none;
}

.custom-progress {
  top: 6px;
  position: relative;
}

.loader {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  display: inline-block;
  border-top: 3px solid #fff;
  border-right: 3px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.show-password-icon {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-50%, -50%);
}

.faq-icon {
  padding: 2px;
}

.vpsmodal {
  .modal-dialog {
    max-width: 1200px;
    width: 100%;
    padding: 0;
    margin: auto;
    overflow-x: auto;
  }
}
.vpstable {
  min-width: 800px;
  max-width: 100%;
  width: 100%;
  overflow: auto;
  thead {
    tr {
      &:first-child {
        th {
          text-align: center;
        }
      }
    }
  }
}

.css-b62m3t-container path,
.form-group svg.css-tj5bde-Svg path {
  fill: #868686;
}

.td-overflow {
  width: 40%;
  word-break: break-all;
  overflow: hidden;
}

.rdw-center-aligned-block > .public-DraftStyleDefault-block > span {
  text-align: center;
  width: 100%;
  display: block;
}

.rdw-right-aligned-block > .public-DraftStyleDefault-block > span {
  text-align: right;
  width: 100%;
  display: block;
}

.pagelimit {
  display: flex !important;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row !important;
  gap: 20px;
  margin-top: 10px !important;
}

.table-field-actions {
  height: 0px;
}

.dashboard-block {
  row-gap: 15px;
}
.pagination.page-list {
  padding-left: 0;
  padding-right: 0;
}
.pagination.page-list .page-item .page-link {
  padding: 8px 8px;
}

@media only screen and (max-width: 576px) {
  .announcement-button {
    width: 100%;
    text-align: end;
  }
  .common-right-button {
    text-align: end;
    margin-left: auto !important;
  }
}

.fileUploadSmall {
  min-height: 70px;
  padding: 10px;
  border: 1px #617182 dashed;
}

.fileUpload {
  min-height: 70px;
  padding: 10px;
  border: 1px dashed #212121;
}

.fileUploadImage {
  min-height: 50px;
  padding: 10px;
  border: 1px lightgrey dotted;
}

.control.control-outline .control__indicator.announc-checkbox {
  border-color: #000;
}

.responsive-table {
  width: 100%;
  overflow: auto;
}
.responsive-table .group-table {
  min-width: 575px;
  max-width: 100%;
  width: 100%;
}

.active-user {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 10px;
}

.toollist-select {
  padding: 10px;
}

@media only screen and (max-width: 576px) {
  .toollist-select {
    padding: 0px;
  }
}

.member-select {
  display: flex;
  align-items: center;
  justify-content: end;
}

.member-select div {
  width: 100%;
}

.member-select .form-group {
  width: 100%;
  text-align: end;
}

.member-select .form-group .member-autocomplete {
  max-width: 300px;
  width: 100%;
  display: inline-block;
}
.top-toolbar.navbar-desktop ul.navbar-nav.nav-right {
  padding-right: 10px;
}

.top-toolbar.navbar-desktop .nav-item-username {
  color: #fff;
  margin-left: 8px;
}

.pass-hideshow svg.show-password-register {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  cursor: pointer;
}

.faq-table.table td {
  vertical-align: top;
}

.back-button {
  color: #399af2;
}

.scroll-select {
  overflow-y: hidden;
}

.input-checkbox {
  width: auto;
  margin: 0;
}

/* AccountInfo.css */
/* .content-wrapper {
  padding: 20px;
} */

.account-info h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.account-info ul {
  list-style-type: none;
  padding: 0;
}

.account-info li {
  font-size: 1.2em;
  margin: 5px 0;
}
.head-li {
  font-weight: 600;
  /* width: 120px; */
}

.glowing-text {
  color: #800080;
  text-shadow:
    0 0 1px #800080,
    0 0 2px #800080,
    0 0 3px #800080;
  animation: glow 1.5s ease-in-out infinite alternate;
}

@keyframes glow {
  from {
    text-shadow:
      0 0 1px #800080,
      0 0 2px #800080,
      0 0 3px #800080;
  }
  to {
    text-shadow:
      0 0 2px #800080,
      0 0 3px #800080,
      0 0 4px #800080;
  }
}
