@import "./assets/css/vendor/bootstrap.css";
@import "./assets/vendor/metismenu/dist/metisMenu.css";
@import "./assets/vendor/switchery-npm/index.css";
@import "./assets/css/icons/line-awesome/css/line-awesome.min.css";
@import "./assets/css/icons/material-design-iconic-font/css/material-design-iconic-font.min.css";
@import "./assets/css/icons/dripicons/dripicons.css";
@import "./assets/css/icons/fontawesome/css/all.min.css";
@import "./assets/vendor/select2/select2.min.css";
@import "./assets/vendor/bootstrap-multiselect-master/bootstrap-multiselect.css";
@import "./assets/vendor/bootstrap-datepicker/bootstrap-datepicker.min.css";
@import "./assets/vendor/bootstrap-daterangepicker/daterangepicker.css";
@import "./assets/vendor/bootstrap-timepicker/bootstrap-timepicker.min.css";
@import "./assets/vendor/bootstrap-fileinput/bootstrap-fileinput.css";
@import "./assets/vendor/fancybox-master/jquery.fancybox.min.css";
@import "./assets/css/common/main.bundle.css";
@import "./assets/css/layouts/vertical/core/main.css";
@import "./assets/css/layouts/vertical/menu-type/default.css";
@import "./assets/css/layouts/vertical/themes/theme-k.css";
@import "./assets/css/style.css";

.circonf-wrapper {
  text-align: center;
}

.circonf {
  $size: 2em;
  $speed: 0.7s;
  margin: 1em;
  display: inline-block;
  width: $size;
  height: $size;
  border-radius: 50%;
  border: 4px solid silver;
  animation: circ-anim $speed linear infinite;
}

.circonf-1 {
  border-bottom-color: grey;
}

.circonf-2 {
  border-color: grey;
  border-bottom-color: transparent;
}

.circonf-3 {
  border-color: grey;
  border-bottom-color: transparent;
  border-left-color: transparent;
}

.circonf-4 {
  border: 3px solid grey;
  position: relative;

  &:after {
    position: absolute;
    content: "";
    width: 80%;
    height: 80%;
    background: transparent;
    top: 50%;
    left: 50;
    margin-left: -40%;
    margin-top: -40%;
    border-radius: 50%;
    border: 2px solid grey;
    box-sizing: border-box;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
}

.circonf-5 {
  border: 3px solid grey;
  position: relative;
  border-bottom-color: transparent;
  border-left-color: transparent;

  &:after {
    $speed: 0.7s;
    position: absolute;
    content: "";
    width: 80%;
    height: 80%;
    background: transparent;
    top: 50%;
    left: 50;
    margin-left: -40%;
    margin-top: -40%;
    border-radius: 50%;
    border: 2px solid grey;
    box-sizing: border-box;
    border-bottom-color: transparent;
    border-left-color: transparent;
    animation: circ-anim ($speed/2) reverse linear infinite;
  }
}

.circonf-6 {
  border-width: 0px;
  border-radius: 50%;
  border-top: 3px solid grey;
  border-right: 3px solid transparent;
}

@keyframes circ-anim {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.loading-1 {
  $size: 0.5em;
  $circumference: 3.14 * 2 * $size;
  $height: 1.2em;

  .circle {
    stroke: #ffff;
    fill: none;
    stroke-width: 3px;
    stroke-dashoffset: -$circumference;
    stroke-dasharray: $circumference;
    animation: loading1 1.4s cubic-bezier(0.42, 0, 0.29, 1) infinite;
    transform: rotate(-90deg);
    transform-origin: 50%;
    position: relative;
  }

  @keyframes loading1 {
    0% {
      stroke-dashoffset: $circumference;
    }

    50% {
      stroke-dashoffset: 0;
    }

    100% {
      stroke-dashoffset: -$circumference;
    }
  }
}

.loading-2 {
  $duration: 0.7s;
  $size: 2em;

  .spinner * {
    box-sizing: border-box;
  }

  .spinner {
    display: inline-block;
    animation-name: anim-spinner;
    animation-duration: $duration;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    .circle {
      width: $size;
      height: ($size/2);
      overflow: hidden;
    }

    .circle-inner {
      transform: rotate(45deg);
      border-radius: 50%;
      border: ($size/8) solid black;
      border-right: ($size/8) solid transparent;
      border-bottom: ($size/8) solid transparent;
      width: 100%;
      height: 200%;
      animation-name: anim-circle-1;
      animation-duration: $duration;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-timing-function: cubic-bezier(0.25, 0.1, 0.5, 1);
    }

    .circle-2 {
      transform: rotate(180deg);

      & .circle-inner {
        animation-name: anim-circle-2;
      }
    }

    @keyframes anim-circle-1 {
      from {
        transform: rotate(60deg);
      }

      to {
        transform: rotate(205deg);
      }
    }

    @keyframes anim-circle-2 {
      from {
        transform: rotate(30deg);
      }

      to {
        transform: rotate(-115deg);
      }
    }

    @keyframes anim-spinner {
      from {
        transform: rotate(0deg);
      }

      to {
        transform: rotate(360deg);
      }
    }
  }
}

.loading-3 {
  .loading {
    background: transparent;
    width: 2em;
    height: 2em;
    position: relative;
    margin: 0 auto;
  }

  .loading:after {
    display: block;
    content: "";
    border-radius: 50%;
    height: 2px;
    width: 2px;
    position: absolute;
    margin: auto;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    box-shadow:
      10px 0 0 2px #333,
      7px 7px 0 2px #999,
      0 10px 0 2px #999,
      -7px 7px 0 2px #999,
      -10px 0 0 2px #999,
      -7px -7px 0 2px #999,
      0 -10px 0 2px #333,
      7px -7px 0 2px #111;
    -webkit-animation: rotate 0.7s steps(8) infinite;
    -o-animation: rotate 0.7s steps(8) infinite;
    animation: rotate 0.7s steps(8) infinite;
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
}

.loading-4 .loading {
  $size: 0.5em;
  width: $size;
  height: $size;
  background: transparent;
  margin: 2em;
  border-radius: 50%;
  animation: circ-anim 0.9s linear infinite;

  box-shadow:
    ($size * 1.2) 0 0 0 #333,
    (-$size/1.5) (-$size) 0 0 #333,
    (-$size/1.5) ($size) 0 0 #333;
}

.loading-5 {
  $size: 0.5em;
  $circumference: 3.14 * 2 * $size;
  $offset: 0.85;
  $circumference: $circumference * $offset;

  & .svg-wrapper {
    background: white;
    width: $size * 3;
    height: $size * 3;
    margin: 0 Auto;
    padding: 5px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.1);
  }

  .circle {
    stroke: #000066;
    fill: none;
    stroke-width: 3px;
    stroke-dashoffset: -$circumference;
    stroke-dasharray: $circumference;
    animation:
      loading5 1.4s linear infinite,
      color1 10s linear infinite;
    transform: rotate(-90deg);
    transform-origin: 50%;
    position: relative;
  }

  @keyframes loading5 {
    0% {
      transform: rotate(0);
      stroke-dashoffset: $circumference;
    }

    50% {
      stroke-dashoffset: 0;
    }

    100% {
      transform: rotate(360deg);
      stroke-dashoffset: -$circumference;
    }
  }

  @keyframes color1 {
    0% {
      stroke: #d8334a;
    }

    10% {
      stroke: #fc6e51;
    }

    20% {
      stroke: #ffce54;
    }

    30% {
      stroke: #a0d468;
    }

    40% {
      stroke: #a0cecb;
    }

    50% {
      stroke: #4fc1e9;
    }

    60% {
      stroke: #8067b7;
    }

    70% {
      stroke: #baa286;
    }

    80% {
      stroke: #ccd1d9;
    }

    90% {
      stroke: #2ecc71;
    }

    100% {
      stroke: #d8334a;
    }
  }
}

.loading-6 {
  svg {
    width: 3.75em;
    transform-origin: center;
    animation: rotate 2s linear infinite;
  }

  circle {
    fill: none;
    stroke: #fc2f70;
    stroke-width: 2;
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }

    50% {
      stroke-dasharray: 90, 200;
      stroke-dashoffset: -35px;
    }

    100% {
      stroke-dashoffset: -125px;
    }
  }
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
  font-family: "Roboto", sans-serif;
}

.wrapper {
  background: #ddd;
  padding: 1em 2em;
  margin: 2em;
  box-shadow: 0px 2px 2px 0px rgba(20, 20, 20, 0.3);
  text-align: center;

  & .item {
    display: inline-block;
    margin: 0.5em 2em;
    vertical-align: middle;
  }
}

#AppDark .MuiSvgIcon-root {
  fill: #c9d1d9;
}

.MuiSvgIcon-root {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
}

.MuiListItemIcon-root {
  display: inline-flex;
  min-width: 30px;
  flex-shrink: 0;
  vertical-align: bottom;
  margin-left: -3px;
}

.dropbtn {
  background-color: #04aa6d;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 110px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  right: 0;
  top: 25p;
  text-align: left;
}

.dropdown-content a {
  padding: 10px 20px;
  text-decoration: none;
  display: block;
  font-weight: 400;
  color: #839bb3;
}

.dropdown-content a:hover {
  background-color: rgba(231, 241, 253, 0.2);
  color: #68439a !important;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}

.dropzone {
  min-height: 70px !important;
}

div.show-image {
  position: relative;
  float: left;
  margin: 5px;
}

div.show-image:hover img {
  opacity: 0.5;
}

div.show-image:hover input {
  display: block;
}

div.show-image input {
  position: absolute;
  display: none;
}

div.show-image input.update {
  top: 0;
  left: 0;
}

div.show-image input.delete {
  top: 0;
  left: 79%;
}

.no-back-red {
  background: transparent;
  border: none;
  color: red;
  cursor: pointer;
}

.img-thumbanail {
  height: 25px;
  width: 45px;
  border: 1px solid #efefef;
}

.img-detail {
  border: 1px solid #efefef;
}

.MuiChip-icon {
  margin-right: -18px !important;
}

.MuiListItemIcon-root-tbl {
  display: inline-flex;
  min-width: 30px;
  flex-shrink: 0;
  vertical-align: middle;
  margin-left: -3px;
}

.css-19kzrtu {
  padding: 0px !important;
}

#container {
  margin: 1rem;
}

.items {
  margin-bottom: 1rem;
}

.attch-container {
  display: flex;
}

.attch-container img {
  width: 200px;
  height: 120px !important;
  height: auto;
}

.attch-container .btns {
  vertical-align: top;
  margin-left: 10px;
  padding: 8px;
  line-height: 10px;
  border: none;
  max-height: 32px;
  cursor: pointer;
}
.attch-container .btns svg {
  height: 15px;
  width: 15px;
}
